//color theme
$sectionSeparateColor: #e5e5e5;
$mainBackground: #f7f8fc;
$color_001: #345a7d;
$color_002: #0082c8;
$color_003: #6d6f75;
$color_004: #212121;
$color_005: #c7c7c7;
$color_006: rgba(210, 210, 210, 0.15);
$color_007: #7f7f7f;
$color_008: #7a7c82;
$color_009: #f1faff;
$color_010: #f8fafb;
$color_011: #252525;
$color_012: #dbdddf;
$color_013: #558ec2;
$color_014: #a5cde3;
$color_015: rgb(255, 243, 221);
$color_016: #94bfe1;
$badge_color_001: #18cfbd;
$badge_bg_001: #f3fcfb;
$badge_color_002: #886cff;
$badge_bg_002: #eae6fd;
$badge_color_003: #ffb401;
$badge_bg_003: #fff8e5;
$badge_color_004: #ff4181;
$badge_bg_004: #f8eff4;

//font style
$fontDefault: "Inter", sans-serif;
