// @media only screen and (max-width: 1125px) {
//   .main_container {
//     .header_wrapper,
//     .nav_wrapper {
//       padding-left: 30px;
//       padding-right: 30px;
//     }
//     .main_wrapper .academic_stp_001 .academic_btn_001 {
//       min-width: 290px;
//     }
//   }
//   .main_container
//     .main_wrapper
//     .academic_stp_001
//     .academic_stp_002_btn
//     .academic_btn_001 {
//     min-width: auto;
//   }
//   .main_container
//     .main_wrapper
//     .academic_stp_001
//     .academic_stp_002_btn
//     .academic_btn_001
//     .mat-radio-label
//     .mat-radio-label-content
//     .radio_info_btn {
//     .radio_info_details {
//       width: 240px;
//       left: auto;
//       right: -40px;
//       &::after {
//         left: auto;
//         right: 36px;
//       }
//     }
//   }
// }

@media only screen and (max-width: 767px) {
  .main_container .text_26 {
    font-size: 16px;
    line-height: 130%;
  }
  .btn_001 {
    font-size: 13px;
    padding: 12px 20px;
  }
 .ng-tns-c3-2.ng-star-inserted {
  .fld_wrapper.fld_mt_15Imp {
    .mat-mdc-radio-button {
      margin-right: 23px;
    }
    .fld_row{
      margin-top: 23px;
    }
  }
  }
  .expansion_content .fld_step_002_wrp {
    margin-top: 0 !important;
  }
  .main_container .main_wrapper .fld_wrapper .fld_row .fld_col_50 {
    max-width: 100%;
    flex: 1 1 auto;
    .radio_box_row {
      padding-bottom: 20px;
    }
  }
  .ng-tns-c4-3.ng-trigger.ng-trigger-height4sAnim.ng-star-inserted {
    padding-top: 20px;
  }
  .degree_type_sd .sm_detail_header.mt_12imp {
    margin-top: 0 !important;
  }
  .form_submited_wrp .submited_head {
    align-items: flex-start;
  }
  // .form_submited_wrp .submited_head .text_26 {
  //   font-size: 28px;
  //   line-height: 40px;
  // }
  .ng-tns-c3-2.ng-trigger.ng-trigger-height4sAnim.ng-star-inserted {
    margin-top: 16px;
  }
  .fld_row.ng-tns-c4-3.ng-star-inserted {
    margin-top: 16px;
  }
}
  // .mat-radio-button .mat-radio-container {
  //   width: 18px;
  //   height: 18px;
  //   .mat-radio-outer-circle {
  //     width: 18px;
  //     height: 18px;
  //   }
  // }
  // .main_container .main_wrapper .fld_wrapper .fld_row {
  //   .fld_col_25,
  //   .fld_col_50 {
  //     flex: 1 1 50%;
  //     max-width: 50%;
  //   }
  // }

// @media only screen and (max-width: 1100px) {
//   .dropdown-menu {
//     &.country-dropdown {
//       width: 335px !important;
//       width: calc(50vw - 83px) !important;
//     }
//   }
// }
// @media only screen and (max-width: 860px) {
//   .main_container
//     .main_wrapper
//     .academic_stp_001
//     .academic_stp_002_btn
//     .academic_btn_001 {
//     width: calc(50% - 30px);
//   }
//   .academic_stp_btn {
//     overflow: hidden;
//   }
//   .main_container .main_wrapper .fld_wrapper .fld_row .fld_col_25 {
//     flex: 1 1 50%;
//     max-width: 50%;
//   }
// }
// @media only screen and (max-width: 750px) {
//   .main_container {
//     .main_wrapper .academic_stp_001 .academic_btn_001 {
//       width: calc(50% - 30px);
//       min-width: auto;
//     }
//     .logo_wrapper {
//       max-width: 100px;
//     }
//     .main_container .header_wrapper,
//     .main_container .nav_wrapper {
//       padding: 15px;
//     }
//   }
// }
// @media only screen and (max-width: 620px) {
//   .main_container {
//     .logo_wrapper img {
//       max-width: 100px;
//     }
//     .header_wrapper,
//     .nav_wrapper {
//       padding: 10px 15px;
//       .help_wrp .nd_hlp_icon {
//         width: 20px;
//         height: auto;
//         margin: auto;
//       }
//     }
//     .text_18 {
//       font-size: 14px;
//     }
//     .text_13 {
//       font-size: 11px;
//       line-height: 18px;
//     }
//     .main_wrapper {
//       padding: 15px;
//       .academic_stp_001 {
//         margin-top: 10px;
//         .academic_stp_002_btn .academic_btn_001 {
//           margin: 5px;
//         }

//         .academic_btn_001 {
//           min-height: auto !important;
//           .deg_type_img {
//             max-width: 30px;
//           }
//           .text_academic_btn {
//             font-size: 13px;
//             margin: 5px 0;
//           }
//           .mat-radio-label {
//             padding: 10px;
//             .mat-radio-label-content {
//               padding-right: 0;
//             }
//           }
//         }
//       }
//       .form_wrapper .form_expansion .expansion_panel {
//         border-radius: 5px;
//         padding: 15px;
//         .sm_detail_header {
//           border-radius: 5px;
//         }
//         .expansion_icon {
//           width: 18px;
//           height: 18px;
//           &.acdmc_intrs {
//             width: 16px;
//             height: 16px;
//           }
//         }
//         .expansion_body app-personal-information {
//           padding-top: 20px;
//         }
//         .expansion_content .detail_text_area {
//           margin-top: 20px;
//         }
//       }
//       .fld_wrapper {
//         margin-top: 20px;
//         .cust_fld .mat-form-field-wrapper {
//           padding-bottom: 15px;
//         }
//         .cust_fld {
//           .mat-form-field-flex .mat-form-field-infix {
//             padding: 12px 15px;
//             .mat-form-field-label-wrapper .mat-form-field-label {
//               font-size: 13px;
//             }
//           }
//           &.mat-form-field-should-float {
//             .mat-form-field-flex .mat-form-field-infix {
//               padding-bottom: 9px;
//               padding-top: 15px;
//               .mat-form-field-label {
//                 transform: translateY(-8px) scale(0.7) perspective(100px)
//                   translateZ(0.001px);
//               }
//               .mat-form-field-label-wrapper {
//                 left: 15px;
//               }
//             }
//             .mat-select-arrow-wrapper {
//               transform: translateY(-2px);
//             }
//           }
//           .mat-form-field-wrapper input.mat-input-element {
//             font-size: 12px;
//           }
//           &.mat-form-field-invalid .mat-form-field-subscript-wrapper {
//             top: calc(100% - 20px);
//             font-size: 10px;
//           }
//         }
//         .phone_fld_wrp {
//           margin-bottom: 15px;
//           .intl-tel-input,
//           .form-control {
//             height: 46px;
//           }
//           .phone_fld.ng-invalid.ng-touched ~ .mat-error {
//             top: 44px;
//             font-size: 11px;
//           }
//         }
//         .title_001 {
//           padding-bottom: 10px;
//           padding-top: 0;
//           &.title_001_agreement {
//             padding-top: 20px;
//           }
//         }
//         .fld_row.mt_20 {
//           margin-top: 0px;
//         }
//         .expansion_btn_wrapper {
//           margin-top: 5px;
//           padding-top: 20px;
//         }
//       }
//       .academic_stp_text_002 {
//         margin-top: 25px;
//         .academic_stp_text_002_sps {
//           margin-bottom: 10px;
//         }
//       }
//       .academic_stp_text_003 .academic_stp_text_003_sps {
//         margin-bottom: 20px;
//       }
//       .academic_stp_002 {
//         .search_curs_wrapper .searh_curs_fld {
//           padding: 10px 20px 10px 45px;
//           background-size: 16px;
//           border-radius: 5px;
//           font-style: 14px;
//         }
//         .curs_list_wrapper .program_list_header.program_main_header {
//           margin-top: 10px;
//           .program_detail_wrapper {
//             padding: 10px;
//             .program_icon {
//               width: 30px;
//               height: 30px;
//               margin: auto 0;
//               margin-right: 10px;
//               img {
//                 max-width: 14px;
//               }
//             }
//           }
//           .program_detail_wrapper .program_text {
//             font-size: 14px;
//             line-height: 16px;
//           }
//           .tree_main_header_icon {
//             width: 17px;
//             height: 17px;
//           }
//         }
//         .main_container
//           .main_wrapper
//           .academic_stp_002
//           .curs_list_wrapper
//           .program_list_item {
//           padding-right: 10px;
//           .prg_list_bdg {
//             font-size: 11px;
//             line-height: 13px;
//             padding: 5px;
//             max-width: 110px;
//           }
//         }
//       }
//       .text_15 {
//         font-size: 13px;
//       }
//       .text_26 {
//         font-size: 1.1em;
//         line-height: 17px;
//       }
//       .text_20 {
//         font-size: 15px;
//         line-height: 17px;
//       }
//       .text_16 {
//         font-size: 12px;
//         line-height: 20px;
//       }
//       .text_14 {
//         font-size: 13px;
//         line-height: 18px;
//       }
//       .mat-form-field-appearance-legacy .mat-form-field-label {
//         top: 13px !important;
//       }
//       .mat-radio-container {
//         width: 16px;
//         height: 16px;
//         .mat-radio-outer-circle,
//         .mat-radio-inner-circle {
//           width: 16px;
//           height: 16px;
//         }
//       }
//       .mat-radio-label-content {
//         font-size: 11px;
//         white-space: normal;
//       }
//       .btn_001 {
//         padding: 9px 19px;
//         font-size: 16px;
//       }
//     }
//   }
//   .main_container .main_wrapper .form_wrapper .form_expansion .expansion_panel {
//     .text_26 {
//       font-size: 18px;
//       line-height: 17px;
//     }
//     .expansion_icon.agrmt_acpt {
//       width: 17px;
//       height: 17px;
//     }
//     .expansion_body app-personal-information .fld_step_002_wrp {
//       margin-top: 0;
//     }
//   }
// }
// @media only screen and (max-width: 580px) {
//   .main_container {
//     .main_wrapper {
//       .academic_stp_001 {
//         .academic_stp_002_btn .academic_btn_001 {
//           min-height: auto !important;
//           height: auto !important;
//           .text_002_academic_btn {
//             text-align: left !important;
//           }
//         }
//         .academic_btn_001 {
//           width: calc(50% - 14px);
//           height: auto;
//           min-height: 150px;
//           &:first-child {
//             margin-right: 7px;
//           }
//           &:last-child {
//             margin-left: 7px;
//           }
//           .text_002_academic_btn {
//             overflow-wrap: break-word;
//             white-space: normal;
//             text-align: center;
//           }
//         }
//       }
//       .academic_stp_001 .academic_stp_002_btn {
//         padding: 0 10px;
//         box-sizing: border-box;
//         .academic_btn_001 {
//           width: calc(50% - 14px) !important;
//           margin-right: 7px !important;
//           margin-left: 7px !important;
//         }
//       }
//     }
//   }
//   .main_container .main_wrapper .fld_wrapper .fld_row {
//     .fld_col_25,
//     .fld_col_50 {
//       flex: 1 1 100%;
//       max-width: 100%;
//     }
//   }
//   .tooltip {
//     font-size: 13px;
//   }
//   .dropdown-menu.country-dropdown {
//     min-width: calc(100vw - 77px);
//   }
//   .main_container .main_wrapper .fld_wrapper .cust_fld .mat-select-value-text {
//     font-size: 12px;
//   }
// }
// @media only screen and (max-width: 480px) {
//   .main_container .main_wrapper .academic_stp_001 .academic_stp_002_btn {
//     flex-direction: column;
//     .academic_btn_001 {
//       width: calc(100% - 14px) !important;
//     }
//   }
//   .degree_type_sd {
//     padding-top: 24px;
//   }
// }


